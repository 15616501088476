<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="480px"
    height="480px"
  >
    <path fill="#f5bc00" d="M44,41H4V10h40V41z" />
    <polygon fill="#eb7900" points="44,26 24,26 4,26 4,10 44,10" />
    <path fill="#eb7900" d="M17,26h-6v3h6V26z" />
    <path fill="#eb7900" d="M37,26h-6v3h6V26z" />
    <rect width="14" height="3" x="17" y="7" fill="#f5bc00" />
    <path fill="#eb0000" d="M17,23h-6v3h6V23z" />
    <path fill="#eb0000" d="M37,23h-6v3h6V23z" />
  </svg>
</template>
