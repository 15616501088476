// store.js

import { defineStore } from "pinia";

export const useStateStore = defineStore("state", {
  state: () => {
    return {
      userInfo: {
        userName: null,
        userAvatar: null,
        userEmail: null,
        userAvatar: null,
        userJWT: null,
        userLoggedIn: false,
        userIP: null, //Current login IP
        loginTime: null, //When user logged in
        loginIP: null, //Last login
        lastLogin: null,
        loginMethod: null,
        auth0Token: null,
      },
      originalUrl: null,
      userLoggedIn: false,

      isFullScreen: false,

      isAsideMobileExpanded: false,
      isAsideLgActive: false,

      darkMode: false,

      isFieldFocusRegistered: false,

      clients: [],
      history: [],
    };
  },

  actions: {
    setUser(property, data) {
      if (typeof property === "object") {
        // property is an object of key/values
        for (let key in property) {
          this.userInfo[key] = property[key];
        }
      } else {
        // property is a single key, data is the value
        this.userInfo[property] = data;
      }
    },

    toggleFullScreen(value) {
      this.isFullScreen = value;
    },

    toggleAsideMobile(expanded) {
      this.isAsideMobileExpanded = expanded;
    },

    toggleAsideLg(active) {
      this.isAsideLgActive = active;
    },

    setDarkMode(mode) {
      this.darkMode = mode;
    },
  },
});
