<script setup>
import { computed, onBeforeMount } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStateStore } from "@/stores/stateStore";
import { useRouter } from "vue-router";
import menu from "@/menu.js";
import NavBar from "@/components/NavBar.vue";
import AsideMenu from "@/components/AsideMenu.vue";
import FooterBar from "@/components/FooterBar.vue";
import Overlay from "@/components/Overlay.vue";

const store = useStateStore();
const router = useRouter();
const loggedIn = computed(() => store.userLoggedIn);
const auth0 = useAuth0();
//Prevent logins if user is not logged in. It won't matter even if they try to hack. They will not be able to fetch data
router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !loggedIn.value && !auth0.isAuthenticated?.value) {
    console.log("Seems we are not logged in", to.fullPath);
    store.originalUrl = to.fullPath;

    next({ name: "login" });
  } else {
    next();
  }
});
onBeforeMount(async () => {});

const isAsideLgActive = computed(() => store.isAsideLgActive);

const overlayClick = () => {
  store.dispatch("asideLgToggle", false);
};
</script>

<template>
  <nav-bar />
  <aside-menu :menu="menu" />
  <router-view />
  <footer-bar />
  <overlay
    v-show="isAsideLgActive"
    z-index="z-30"
    @overlay-click="overlayClick"
  />
</template>
