import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { darkModeKey } from "@/config.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { createAuth0 } from "@auth0/auth0-vue";
import { createPinia } from "pinia";
import { VueReCaptcha } from "vue-recaptcha-v3";

import "./css/main.css";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

/* Fetch sample data */
store.dispatch("fetch", "clients");
store.dispatch("fetch", "history");

/* Dark mode */
const localStorageDarkModeValue = localStorage.getItem(darkModeKey);

if (
  (localStorageDarkModeValue === null &&
    window.matchMedia("(prefers-color-scheme: dark)").matches) ||
  localStorageDarkModeValue === "1"
) {
  store.dispatch("darkMode");
}

/* Default title tag */
const defaultDocumentTitle = "Zimpricecheck Admin";

/* Collapse mobile aside menu on route change */
router.beforeEach((to) => {
  store.dispatch("asideMobileToggle", false);
  store.dispatch("asideLgToggle", false);
});

router.afterEach((to) => {
  /* Set document title from route meta */

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }

  /* Full screen mode */
  store.dispatch("fullScreenToggle", !!to.meta.fullScreen);
});

createApp(App)
  .component("Datepicker", Datepicker)
  .component("EasyDataTable", Vue3EasyDataTable)
  .use(
    createAuth0({
      domain: "dev-eelb86bjtpe21lbm.uk.auth0.com",
      clientId: "cdhIcSeiSJ6jl5Oaa1ZT262KU1iZRlTk",
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: "https:/zimpricecheck.xyz",
      },
    })
  )
  .use(createPinia())
  .use(store)
  .use(router)
  .use(VueTelInput, {
    mode: "international",
  })
  .use(VueReCaptcha, { siteKey: "6Lc6p7gmAAAAANSspMB8L8HEaRqniWtOT9raQNWA" })
  .mount("#app");
