// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard",
    },
    path: "/",
    name: "home",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/views/admin/traffic/Traffic.vue"
      ),
  },

  {
    meta: {
      title: "Profile",
    },
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
  },
  {
    meta: {
      title: "Ui",
    },
    path: "/ui",
    name: "ui",
    component: () => import(/* webpackChunkName: "ui" */ "@/views/Ui.vue"),
  },

  {
    meta: {
      title: "Login",
      fullScreen: true,
    },
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    meta: {
      title: "Error",
      fullScreen: true,
    },
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
  },
  {
    meta: { title: "Purge All Caches", fullScreen: false },
    path: "/purge-caches",
    name: "purge",
    component: () => import("@/views/PurgeCaches.vue"),
  },
  {
    meta: { title: "Update Fuel Prices", fullScreen: false },
    path: "/update-fuel",
    name: "fuel",
    component: () => import("@/views/prices/FuelPrices.vue"),
  },
  {
    meta: { title: "LP Gas Prices", fullScreen: false },
    path: "/update-lpgas",
    name: "lpgas",
    component: () => import("@/views/prices/LPGasPrices.vue"),
  },
  {
    meta: {
      title: "Update Rates",
      fullScreen: false,
    },
    path: "/update-rates",
    name: "urates",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/rates/UpdateRates.vue"),
  },
  ,
  {
    meta: {
      title: "Update Official Market Rates",
      fullScreen: false,
    },
    path: "/update-orates",
    name: "uorates",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/rates/UpdateORates.vue"),
  },
  {
    meta: {
      title: "Update Official FX Allocations Rates",
      fullScreen: false,
    },
    path: "/update-oallrates",
    name: "uoallrates",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/rates/UpdateOAll.vue"),
  },

  {
    meta: {
      title: "Update Prices",
      fullScreen: false,
    },
    path: "/update-prices",
    name: "uprices",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Groceries.vue"),
  },

  {
    meta: {
      title: "Update Mbare Prices",
      fullScreen: false,
    },
    path: "/mbare-prices",
    name: "mbare",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Mbare.vue"),
  },

  {
    meta: {
      title: "Update Ecocash Tariffs",
      fullScreen: false,
    },
    path: "/ecocash",
    name: "ecocash",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Ecocash.vue"),
  },
  {
    meta: {
      title: "Update Liquid Prices",
      fullScreen: false,
    },
    path: "/liquid-prices",
    name: "liquid",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Liquid.vue"),
  },
  {
    meta: {
      title: "Update Inflation",
      fullScreen: false,
    },
    path: "/update-inflation",
    name: "inflation",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/stats/Inflation.vue"),
  },

  {
    meta: {
      title: "Update Econet Bundles",
      fullScreen: false,
    },
    path: "/update-econet-prices",
    name: "econetbundleprices",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Econet.vue"),
  },
  {
    meta: {
      title: "Update NetOne Bundles",
      fullScreen: false,
    },
    path: "/update-netone-bundles",
    name: "netonebundles",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/NetOne.vue"),
  },
  {
    meta: {
      title: "Update Telecel Bundles",
      fullScreen: false,
    },
    path: "/update-telecel-bundles",
    name: "telecelbundles",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Telecel.vue"),
  },
  {
    meta: {
      title: "Update ZESA Tariffs",
      fullScreen: false,
    },
    path: "/zesa-tariffs",
    name: "zesatariffs",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/ZESA.vue"),
  },

  {
    meta: {
      title: "TelOne Account Details",
      fullScreen: false,
    },
    path: "/telone-details",
    name: "telonedetails",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/telone/CustomerDetails.vue"
      ),
  },
  {
    meta: {
      title: "TelOne Account Recharge",
      fullScreen: false,
    },
    path: "/telone-recharge",
    name: "telonerecharge",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/telone/Recharge.vue"),
  },
  ,
  {
    meta: {
      title: "Update TelOne prices",
      fullScreen: false,
    },
    path: "/telone-prices",
    name: "telone",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Telone.vue"),
  },
  {
    meta: {
      title: "Update Utande Prices",
      fullScreen: false,
    },
    path: "/utande-prices",
    name: "utande",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Utande.vue"),
  },

  {
    meta: {
      title: "Update Delta Drinks Prices",
      fullScreen: false,
    },
    path: "/delta-prices",
    name: "delta",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Delta.vue"),
  },
  {
    meta: {
      title: "Update Delta Alcohol Prices",
      fullScreen: false,
    },
    path: "/deltaa-prices",
    name: "detala",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/DeltaA.vue"),
  },
  {
    meta: {
      title: "Update Pepsi Prices",
      fullScreen: false,
    },
    path: "/pepsi-prices",
    name: "pepsi",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Pepsi.vue"),
  },
  {
    meta: {
      title: "Update Water Prices",
      fullScreen: false,
    },
    path: "/water-prices",
    name: "water",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/Water.vue"),
  },
  {
    meta: {
      title: "Update ZUPCO Fares",
      fullScreen: false,
    },
    path: "/zupco-fares",
    name: "zupco",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/ZUPCO.vue"),
  },
  {
    meta: {
      title: "Update License Fees",
      fullScreen: false,
    },
    path: "/license-fees",
    name: "licensefees",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/LicenseFees.vue"),
  },
  {
    meta: {
      title: "Update Bus Fares",
      fullScreen: false,
    },
    path: "/busfares",
    name: "busfares",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/BusFares.vue"),
  },
  {
    meta: {
      title: "Toll Gate Fees",
      fullScreen: false,
    },
    path: "/tollgate-fees",
    name: "tollgatefees",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/prices/TollGates.vue"),
  },
  {
    meta: {
      title: "ZESA Admin",
      fullScreen: false,
    },
    path: "/zesa-admin",
    name: "zesaadmin",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/admin/zesa/ZESA.vue"),
  },
  {
    meta: {
      title: "Airtime Balance",
      fullScreen: false,
    },
    path: "/airtime-balance",
    name: "airtimebalance",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/admin/airtime/Bal.vue"),
  },
  {
    meta: {
      title: "Send WA Message",
      fullScreen: false,
    },
    path: "/send-wa",
    name: "sendwa",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/admin/whatsapp/Chat.vue"),
  },
  {
    meta: {
      title: "TelOne ADSL Sales",
      fullScreen: false,
    },
    path: "/telone-data",
    name: "telonedata",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/admin/telone/TelOne.vue"),
  },
  {
    meta: {
      title: "Zimpricecheck Sales",
      fullScreen: false,
    },
    path: "/zpc-orders",
    name: "zpcorders",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/sales/Orders.vue"),
  },
  {
    meta: {
      title: "Zimpricecheck View Order",
      fullScreen: false,
    },
    path: "/zpc-order/:order_num?",
    name: "zpcorder",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/sales/Order.vue"),
  },
  {
    meta: {
      title: "Update Product Inventory",
      fullScreen: false,
    },
    path: "/productstocks",
    name: "productstocks",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/admin/airtime/Stocks.vue"
      ),
  },
  {
    meta: {
      title: "Update NetOne Inventory",
      fullScreen: false,
    },
    path: "/netone-config",
    name: "netoneconfig",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/admin/inventories/NetOneConfig.vue"
      ),
  },
  {
    meta: {
      title: "Update USD Airtime Inventory",
      fullScreen: false,
    },
    path: "/usdairtime-config",
    name: "usdairtimeconfig",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/admin/inventories/USDAirtimeConfig.vue"
      ),
  },
  {
    meta: {
      title: "Update ZESA Inventory",
      fullScreen: false,
    },
    path: "/zesa-config",
    name: "zesaconfig",
    component: () => import("@/views/admin/inventories/ZESAConfig.vue"),
  },
  {
    meta: {
      title: "Update Government Fines",
      fullScreen: false,
    },
    path: "/govt-fines",
    name: "govtfines",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/govt/Fines.vue"),
  },
  ,
  {
    meta: {
      title: "Update Birth/Death Fees",
      fullScreen: false,
    },
    path: "/births-deaths",
    name: "birthsdeaths",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/govt/BirthsDeaths.vue"),
  },
  ,
  {
    meta: {
      title: "Update Citizenship Status Fees",
      fullScreen: false,
    },
    path: "/citizen-status",
    name: "citizenstatus",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/govt/CitizenShip.vue"),
  },
  ,
  {
    meta: {
      title: "Update PassPort Fees",
      fullScreen: false,
    },
    path: "/passport-fees",
    name: "passportfees",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/govt/Passports.vue"),
  },
  ,
  {
    meta: {
      title: "Update PassPort Fees",
      fullScreen: false,
    },
    path: "/passport-sadc",
    name: "passportsadc",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/govt/PassportsSADC.vue"),
  },
  {
    meta: {
      title: "Update ZBC Licence",
      fullScreen: false,
    },
    path: "/zbc-fees",
    name: "zbcfees",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/govt/ZBCLicence.vue"),
  },

  {
    meta: {
      title: "Update CVR Licence",
      fullScreen: false,
    },
    path: "/cvr-fees",
    name: "cvrfees",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/govt/CVRLicence.vue"),
  },
  {
    meta: {
      title: "Manage API Keys",
      fullScreen: false,
    },
    path: "/api-keys",
    name: "apikeys",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/admin/users/ManageApiKeys.vue"
      ),
  },
  {
    path: "/usd-recharge",
    name: "usdrecharge",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/admin/airtime/USDRecharge.vue"
      ),
  },
  {
    path: "/usd-pinless",
    name: "usdpinless",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/admin/airtime/Pinless.vue"
      ),
  },
  {
    path: "/fx-wholesale",
    name: "fxwholesale",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/rates/WholeSaleAuction.vue"
      ),
  },
  {
    path: "/sales-charts",
    name: "salescharts",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/sales/Analytics.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

export default router;
