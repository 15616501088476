import { createStore } from "vuex";
import axios from "axios";
import { darkModeKey } from "@/config.js";

export default createStore({
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,
    userJWT: null,
    userLoggedIn: false,
    userIP: null, //Current login IP
    loginTime: null, //When user logged in
    loginIP: null, //Last login
    lastLogin: null,
    loginMethod: null,
    auth0Token: null,

    /* fullScreen - fullscreen form layout (e.g. login page) */
    isFullScreen: false,

    /* Aside */
    isAsideMobileExpanded: false,
    isAsideLgActive: false,

    /* Dark mode */
    darkMode: false,

    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    clients: [],
    history: [],
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value;
    },

    /* User */
    user(state, payload) {
      if (payload.name) {
        state.userName = payload.name;
      }
      if (payload.email) {
        state.userEmail = payload.email;
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar;
      }
      if (payload.jwt) {
        state.userJWT = payload.jwt;
      }
      if (payload.loggedin) {
        state.userLoggedIn = payload.loggedin;
      }
      if (payload.ip) {
        state.userIP = payload.ip;
      }
      if (payload.loginTime) {
        state.loginTime = payload.loginTime;
      }
      if (payload.loginIP) {
        state.loginIP = payload.loginIP;
      }
      if (payload.lastLogin) {
        state.lastLogin = payload.lastLogin;
      }
      if (payload.loginMethod) {
        state.loginMethod = payload.loginMethod;
      }
      if (payload.auth0Token) {
        state.auth0Token = payload.auth0Token;
      }
    },
  },
  actions: {
    asideMobileToggle({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded;

      document
        .getElementById("app")
        .classList[isShow ? "add" : "remove"]("ml-60", "lg:ml-0");

      document.documentElement.classList[isShow ? "add" : "remove"](
        "m-clipped"
      );

      commit("basic", {
        key: "isAsideMobileExpanded",
        value: isShow,
      });
    },

    asideLgToggle({ commit, state }, payload = null) {
      commit("basic", {
        key: "isAsideLgActive",
        value: payload !== null ? payload : !state.isAsideLgActive,
      });
    },

    fullScreenToggle({ commit, state }, value) {
      commit("basic", { key: "isFullScreen", value });

      document.documentElement.classList[value ? "add" : "remove"](
        "full-screen"
      );
    },

    darkMode({ commit, state }) {
      const value = !state.darkMode;

      document.documentElement.classList[value ? "add" : "remove"]("dark");

      localStorage.setItem(darkModeKey, value ? "1" : "0");

      commit("basic", {
        key: "darkMode",
        value,
      });
    },
  },
  modules: {},
});
